import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import NewReleases from "../components/NewReleases";

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <div
      className="full-width-image"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "150px",
          lineHeight: "1",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1
          id="index-heading"
          className="has-text-weight-bold is-size-2 has-text-centered"
          style={{
            color: "white",
            lineHeight: "1.125",
            padding: "0.25em",
          }}
        >
          {title}
        </h1>
        <p
          id="index-subheading"
          className="has-text-weight-bold is-size-5 has-text-centered"
          style={{
            color: "white",
            lineHeight: "1",
            padding: "0.5em",
          }}
        >
          {subheading}
        </p>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-12">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h2 className="title">{mainpitch.title}</h2>
                  </div>
                  <div className="tile">
                    <p>{mainpitch.description}</p>
                  </div>
                </div>
                <div className="content">
                  <div className="tile">
                    <h2 className="title">{heading}</h2>
                  </div>
                  <div className="tile">
                    <p>{description}</p>
                  </div>
                </div>
                <div className="content" style={{ padding: '1rem 0' }}>
                  <h3 className="has-text-weight-semibold has-text-centered is-size-2">
                    Recently Published Resources
                  </h3>
                  <NewReleases />
                </div>
                <div className="content" style={{ padding: '1rem 0' }}>
                  <h3 className="has-text-weight-semibold has-text-centered is-size-2">
                    Resources We Produce
                  </h3>
                  <Features gridItems={intro.blurbs} />
                </div>
                <div className="content" style={{ padding: '1rem 0' }}>
                  <h3 className="has-text-weight-semibold has-text-centered is-size-2">
                    Latest Blog Articles
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="button" to="/blog">
                      Read Our Blog
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <Helmet>
        <title>Schoolhouse by the Sea: An Educational Resource Center</title>
        <meta property="og:title" content="Schoolhouse by the Sea: An Educational Resource Center" />
        <meta name="twitter:title" content="Schoolhouse by the Sea: An Educational Resource Center" />
      </Helmet>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            title
            text
          }
        }
      }
    }
  }
`;
