import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

class NewReleases extends React.Component {
    render() {
        const { data } = this.props
        const { edges: posts } = data.allMarkdownRemark

        return (
            <div className="columns is-multiline">
                {posts &&
                posts.map(({ node: post }) => (
                    <div className="is-parent column is-4" key={post.id}>
                        <article
                            className={`blog-list-item tile is-child box notification`}
                        >
                            <header>
                                <p className="post-meta">
                                    <Link
                                        className="title has-text-primary is-size-4"
                                        to={post.fields.slug}
                                    >
                                        {post.frontmatter.title}
                                    </Link>
                                    <br />
                                    <span className="subtitle is-size-5 is-block">
                          {post.frontmatter.date}
                        </span>
                                </p>
                            </header>
                            <p>
                                {post.frontmatter.description}
                            </p>
                        </article>
                    </div>
                ))}
            </div>
        )
    }
}

NewReleases.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

export default () => (
    <StaticQuery
        query={graphql`
      query NewReleasesQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___dateLastUpdated] }
          limit: 6,
          filter: { frontmatter: { templateKey: { in: ["educator-resource", "student-resource"] } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 180)
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                datePosted(formatString: "MMMM DD, YYYY")
                dateLastUpdated(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
        render={(data, count) => <NewReleases data={data} count={count} />}
    />
)
